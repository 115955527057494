body {
  margin: 0;
  font-family: var(--body-font), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--themed-bg-color);
  color: var(--themed-font-color);
  caret-color: var(--brand-primary);
}

::-moz-selection {
  color: var(--themed-bg-color);
  background-color: var(--save-indicator-color);
}
::selection {
  color: var(--themed-bg-color);
  background-color: var(--save-indicator-color);
}

h1 {
  font-family: var(--title-font);
}

h2 {
  font-family: var(--title-font);
}

h3 {
  font-family: var(--title-font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.script-screenplay p:first-of-type {
  text-transform: uppercase;
}

.screenplay-scene {
  text-transform: uppercase;
}

.screenplay-scene:not(:first-of-type) {
  margin-top: 15px !important;
}

.screenplay-action {
  text-transform: none !important;
  margin-top: 15px !important;
}

.screenplay-character {
  text-transform: uppercase !important;
  margin: 15px auto 0 auto !important;
  width: 30%;
}

.screenplay-dialogue {
  text-transform: none !important;
  margin: 0 auto 0 auto !important;
  /* width: 50%; */
}

.screenplay-parenthetical {
  text-transform: none !important;
  margin: 0px auto !important;
  /* width: 20%; */
}

.screenplay-transition {
  text-align: right;
  text-transform: uppercase;

  margin: 15px 0 0 auto !important;
}

@media screen and (max-width: 768px) {
  .screenplay-scene {
    border-left: 4px solid var(--screenplay-scene-color);
    padding-left: 4px;
  }
  .screenplay-action {
    margin-top: 15px !important;
    border-left: 4px solid var(--screenplay-action-color);
    padding-left: 4px;
  }

  .screenplay-character {
    margin: 15px 0;
    border-left: 4px solid var(--screenplay-character-color);
    padding-left: 4px;
    width: 100%;
  }

  .screenplay-dialogue {
    margin: 0px;
    border-left: 4px solid var(--screenplay-dialogue-color);
    padding-left: 4px;
    width: 100%;
  }

  .screenplay-parenthetical {
    text-transform: none !important;
    margin: 0px;
    border-left: 4px solid var(--screenplay-parenthetical-color);
    padding-left: 4px;
    width: 100%;
    margin-left: 0px !important;
  }

  .screenplay-transition {
    margin: 15px 0px 0px 0px;
    border-left: 4px solid var(--screenplay-transition-color);
    padding-left: 4px;
  }
}

.screenplay-scene--active {
  background-color: var(--screenplay-scene-hover-color);
}

.restructure-separator {
  width: 100%;
  color: var(--themed-font-color);
  font-size: 12px;
  font-weight: bold;
  border-top: 1px dotted var(--brand-primary);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin: 10px 0px 5px 0px;
}

.restructure-separator:hover {
  color: var(--brand-primary);
}

.restructure-separator[breakpointSelected="true"] {
  color: var(--themed-bg-color);
  background-color: var(--themed-subhead-color);
}

.restructure-separator[breakpointSelected="true"]:hover {
  background-color: var(--brand-primary);
  color: var(--themed-bg-color);
}

.restructure-separator:after {
  content: attr(my-text);
}

@media (min-width: 768px) {
  .screenplay-scene,
  .screenplay-action,
  .screenplay-character,
  .screenplay-dialogue,
  .screenplay-parenthetical,
  .screenplay-transition {
    padding-top: 3px;
    padding-left: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .screenplay-character {
    margin: 15px auto 0 auto !important;
    /* width: 30%; */
    padding-left: 35%;
    padding-right: 35%;
  }

  .screenplay-dialogue {
    margin: 0 auto 0 auto !important;
    padding-left: 25%;
    padding-right: 25%;
  }

  .screenplay-parenthetical {
    margin: 0px auto auto auto !important;
    /* width: 100%; */
    padding-left: 30%;
    padding-right: 50%;
  }

  .screenplay-transition {
    margin: 15px 0 0 auto !important;
  }
}

input,
select,
textarea {
  color: var(--themed-font-color);
  background-color: var(--themed-input-bg-color);
  border: var(--themed-border-color);
  font-size: 16px;
  font-family: var(--body-font);
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

:root {
  /* themes */
  --logo: url(/images/hilker_logo.png);
  --themed-bg-color: #ffffff;
  --themed-input-bg-color: #f1f1f1;
  --themed-font-color: #333333;
  --themed-focus-color: #999999;
  --themed-border-color: #333333;
  --themed-header-color: #555555;
  --themed-light-bg: #f1f1f1;
  --themed-light-text: #333333;
  --themed-subhead-color: #cccccc;
  --themed-asset-header-color: #666666;
  --themed-save-notification-color: #000000;
  --themed-input-focus-color: #f1f1f1;
  --themed-add-item-button: blue;
  --themed-no-content-color: #cccccc;
  --themed-selected-text-bg-color: #cccccc;
  --themed-active-scene-color: #cccccc;
  --themed-add-scene-color: #cccccc;
  --themed-plot-bg-color: #fafafa;
  --themed-plot-header-bg-color: #cccccc;
  --themed-alert-box-bg-color: #fffad1;
  --themed-snippet-color: #999999;

  /* base stuff */
  --brand-primary: #0066cc;
  --brand-primary-translucent: #0066cc15;
  --alert-box-bg: #0066cc14;
  /* --brand-primary: #979797; */
  --body-font: "Noto Sans JP";
  --title-font: "Noto Sans JP";
  --storySection-color: rgba(75, 75, 75, 1); /* #4b4b4b */
  --scene-color: rgba(0, 187, 52, 1); /* #00bb34 */
  --storybeat-color: rgba(255, 145, 0, 1); /* #ff9100 */
  --storyline-color: rgba(230, 130, 0, 1);
  --dialogue-color: rgba(0, 144, 255, 1); /* #0091FF */
  --character-color: rgba(175, 187, 0, 1); /* #afbb00 */
  --arc-color: rgba(68, 0, 255, 1); /* #4400ff */
  --location-color: rgba(0, 0, 0, 1); /* #000000 */
  --macguffin-color: rgba(172, 3, 194, 1); /* #ac03c2 */
  --storybeat-color--uncompleted: rgba(255, 145, 0, 0.6);
  --storyline-color--uncompleted: rgba(230, 130, 0, 0.6);
  --dialogue-color--uncompleted: rgba(0, 144, 255, 0.6);
  --character-color--uncompleted: rgba(175, 187, 0, 0.6);
  --arc-color--uncompleted: rgba(68, 0, 255, 0.6);
  --location-color--uncompleted: rgba(0, 0, 0, 0.6);
  --macguffin-color--uncompleted: rgba(172, 3, 194, 0/6);
  --scene-color--plot-bg: rgba(0, 187, 52, 0.1);
  --storybeat-color--plot-bg: rgba(255, 145, 0, 0.1);
  --storyine-color--plot-bg: rgba(230, 130, 0, 0.1);
  --dialogue-color--plot-bg: rgba(0, 144, 255, 0.1);
  --character-color--plot-bg: rgba(175, 187, 0, 0.1);
  --arc-color--plot-bg: rgba(68, 0, 255, 0.1);
  --location-color--plot-bg: rgba(0, 0, 0, 0.1);
  --macguffin-color--plot-bg: rgba(172, 3, 194, 0.1);
  --facebook-color: #3b5998;
  --facebook-color--hover: #2c4372;
  --google-color: #cc3333;
  --google-color--hover: #af2a2a;
  --tab-handle-color: rgba(255, 255, 255, 0.4);

  --manuscript-bold-color: #333333;
  --manuscript-italic-color: #666666;
  --manuscript-underline-color: #555555;

  --screenplay-scene-color: #00bb34;
  --screenplay-scene-hover-color: #00bb3415;
  --screenplay-action-color: #0066cc;
  --screenplay-action-hover-color: #0066cc15;
  --screenplay-transition-color: #333333;
  --screenplay-transition-hover-color: #33333315;
  --screenplay-dialogue-color: #0090ff;
  --screenplay-dialogue-hover-color: #0090ff15;
  --screenplay-character-color: #afbb00;
  --screenplay-character-hover-color: #afbb0015;
  --screenplay-parenthetical-color: #ff9100;
  --screenplay-parenthetical-hover-color: #ff910015;

  --tab-text-color: #ffffff;
  --themed-header-link-color: #333333;
  --themed-header-link-color--hover: #111111;
  --rollover-transition--fast: background-color 0.2s;
  --transition--fast: 0.2s;
  --focus-mode-transition: visibility 0.75s, opacity 0.75s,
    background-color 0.75s;
  --error-color: #ffffff;
  --error-background-color: rgba(255, 0, 0, 0.3);
  --delete-color: rgba(255, 0, 0, 0.3);
  --white-color: #ffffff;
  --black-color: #333333;
  --really-black-color: #000000;
  --new-color: #009e66;
  --light-border-color: #dddddd;
  --modal-bg-color: rgba(0, 0, 0, 0.3);
  --cancel-button-color: rgba(255, 0, 0, 0.6);
  --cancel-button-color--hover: rgba(255, 0, 0, 1);
  --okay-button-color: rgba(0, 150, 0, 0.6);
  --okay-button-color--hover: rgba(0, 150, 0, 1);
  --delete-button-color: rgba(200, 0, 0, 0.6);
  --delete-button-color--hover: rgba(200, 0, 0, 1);
  --remove-button-color: rgba(200, 0, 0, 0.6);
  --remove-button-color--hover: rgba(200, 0, 0, 1);
  --disabled-button-color: rgba(255, 255, 255, 0.3);
  --disabled-button-color--hover: rgba(255, 255, 255, 0.3);
  --download-color: #72d677;

  --option-active-color: #72d677;
  --option-inactive-color: #cccccc;
  --not-used-in-project-color: #333333;
  --not-used-in-project-bg: rgba(255, 0, 0, 0.8);

  --save-indicator-color: orange;
}

@media (prefers-color-scheme: dark) {
  :root {
    --logo: url(/images/hilker_logo.png);
    --themed-bg-color: #333333;
    --themed-input-bg-color: #222222;
    --themed-font-color: #ffffff;
    --themed-border-color: #666666;
    --themed-header-color: #ffffff;
    --themed-light-bg: #555555;
    --themed-light-text: #333333;
    --themed-subhead-color: #a0a0a0;
    --themed-asset-header-color: #999999;
    --themed-save-notification-color: #ffffff;
    --themed-input-focus-color: #444444;
    --themed-add-item-button: orange;
    --themed-header-link-color: #a3a3a3;
    --themed-header-link-color--hover: #f1f1f1;
    --themed-no-content-color: #666666;
    --themed-selected-text-bg-color: #999999;
    --themed-focus-color: #666666;
    --themed-active-scene-color: #bbbbbb;
    --themed-add-scene-color: #999999;
    --themed-plot-bg-color: #303030;
    --themed-plot-header-bg-color: #666666;
    --themed-alert-box-bg-color: #555555;
    --themed-snippet-color: #aaaaaa;
    --themed-blur-bg-color: rgba(255, 255, 255, 0.025);
  }
}

[data-theme="dark"] {
  --logo: url(/images/hilker_logo.png);
  --themed-bg-color: #333333;
  --themed-input-bg-color: #222222;
  --themed-font-color: #ffffff;
  --themed-border-color: #666666;
  --themed-header-color: #ffffff;
  --themed-light-bg: #555555;
  --themed-light-text: #333333;
  --themed-subhead-color: #a0a0a0;
  --themed-asset-header-color: #999999;
  --themed-save-notification-color: #ffffff;
  --themed-input-focus-color: #444444;
  --themed-add-item-button: orange;
  --themed-header-link-color: #a3a3a3;
  --themed-header-link-color--hover: #f1f1f1;
  --themed-no-content-color: #666666;
  --themed-selected-text-bg-color: #999999;
  --themed-focus-color: #d0d0d0;
  --themed-active-scene-color: #bbbbbb;
  --themed-add-scene-color: #999999;
  --themed-plot-bg-color: #303030;
  --themed-plot-header-bg-color: #666666;
  --themed-alert-box-bg-color: #555555;
  --themed-snippet-color: #aaaaaa;
  --themed-blur-bg-color: rgba(255, 255, 255, 0.025);
}

[data-theme="light"] {
  --logo: url(/images/hilker_logo.png);
  --themed-bg-color: #ffffff;
  --themed-input-bg-color: #f1f1f1;
  --themed-font-color: #333333;
  --themed-focus-color: #999999;
  --themed-border-color: #333333;
  --themed-header-color: #555555;
  --themed-light-bg: #f1f1f1;
  --themed-light-text: #333333;
  --themed-subhead-color: #cccccc;
  --themed-asset-header-color: #666666;
  --themed-save-notification-color: #000000;
  --themed-input-focus-color: #f1f1f1;
  --themed-add-item-button: blue;
  --themed-no-content-color: #cccccc;
  --themed-selected-text-bg-color: #cccccc;
  --themed-active-scene-color: #cccccc;
  --themed-add-scene-color: #cccccc;
  --themed-plot-bg-color: #fafafa;
  --themed-plot-header-bg-color: #cccccc;
  --themed-alert-box-bg-color: #fffad1;
  --themed-snippet-color: #999999;
  --themed-blur-bg-color: rgba(0, 0, 0, 0.025);
}

/* Selection Colours */
/* Scenes */
.note_selection_scene {
  background-color: rgba(0, 187, 52, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_scene:hover {
  background-color: rgba(0, 100, 52, 0.5);
  display: inline;
}

.note_selection_storySection {
  background-color: rgba(75, 75, 75, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_storySection:hover {
  background-color: rgba(75, 75, 75, 0.5);
  display: inline;
}

.note_selection_location {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_location:hover {
  background-color: rgba(0, 0, 0, 0.5);
  display: inline;
}

/* Story Beats */
.note_selection_storybeat {
  background-color: rgba(255, 145, 0, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_storybeat:hover {
  background-color: rgba(255, 145, 0, 0.5);
  display: inline;
}

/* Storylines */
.note_selection_storyline {
  background-color: rgba(200, 125, 0, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_storyline:hover {
  background-color: rgba(200, 125, 0, 0.5);
  display: inline;
}

/* Dialogue */
.note_selection_dialogue {
  background-color: rgba(0, 144, 255, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_dialogue:hover {
  background-color: rgba(0, 144, 255, 0.5);
  display: inline;
}

/* Characters */
.note_selection_character {
  background-color: rgba(175, 187, 0, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_character:hover {
  background-color: rgba(175, 187, 0, 0.5);
  display: inline;
}

/* Arcs */
.note_selection_arc {
  background-color: rgba(68, 0, 255, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_arc:hover {
  background-color: rgba(68, 0, 255, 0.5);
  display: inline;
}

/* Macguffins */
.note_selection_macguffin {
  background-color: rgba(68, 0, 255, 0.2);
  cursor: pointer;
  display: inline;
}

.note_selection_macguffin:hover {
  background-color: rgba(68, 0, 255, 0.5);
  display: inline;
}

[contentEditable="true"]:empty:not(:focus):before {
  content: attr(placeholder);
  color: grey;
  font-style: italic;
}

@media print {
  html {
    visibility: hidden;
  }

  #root {
    display: none;
  }

  #print-housing {
    visibility: visible !important;
    color: var(--black-color) !important;
    background-color: var(--white-color) !important;
  }

  .screenplay-scene,
  .screenplay-action,
  .screenplay-character,
  .screenplay-dialogue,
  .screenplay-parenthetical,
  .screenplay-transition {
    border-left: 0px;
    padding-left: 0px;
  }

  .screenplay-scene,
  .screenplay-action,
  .screenplay-character,
  .screenplay-dialogue,
  .screenplay-parenthetical,
  .screenplay-transition {
    padding-top: 3px;
    padding-left: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .screenplay-character {
    margin: 15px 35% 0 35% !important;
  }

  .screenplay-dialogue {
    margin: 0 25% 0 25% !important;
  }

  .screenplay-parenthetical {
    margin: 0px 30% auto 30% !important;
    /* width: 100%; */
  }

  .screenplay-transition {
    margin: 15px 0 0 auto !important;
  }

  .print-section {
    page-break-after: always;
    overflow: hidden;
  }

  .project-title {
    margin-top: 33%;
    text-transform: uppercase;
    text-align: center;
  }

  [data-type="screenplay"] {
    font-family: "Courier Prime", monospace;
  }

  .project-credit,
  .project-authors {
    text-align: center;
  }

  .title-page {
    position: relative;
    height: 70vh;
  }

  .print-section-title {
    font-size: 20px;
    margin: 60px 0px;
  }

  .print-scene-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    page-break-after: always;
    overflow: hidden;
  }

  .print-scene-text {
    margin-bottom: 40px;
  }

  .footer {
    display: none;
  }

  .project-meta {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: right;
  }
}

#print-housing {
  visibility: hidden;
}

.stuck {
  border-radius: 0px 0px 8px 8px;
}

@page {
  size: auto;
  margin: 25mm 25mm 25mm 25mm;

  @top-left {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
    content: "Page " + counter(page);
  }
  @bottom-left {
    content: "";
  }

  @bottom-right {
    content: "";
  }
}

.spinning {
  -webkit-animation: spin-icon 2s infinite linear;
  animation: spin-icon 2s infinite linear;
}

@-webkit-keyframes spin-icon {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin-icon {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.outline-header {
  font-size: 16px;
}

.outline-date {
  text-align: right;
  font-size: 10px;
  margin-bottom: 20px;
}

.outline-section {
  margin-bottom: 20px;
  font-size: 12px;
}

.outline-section-title {
  font-weight: bold;
  font-size: 14px;
}

.outline-section-description {
  margin-bottom: 20px;
  font-style: italic;
}

.outline-scene {
  margin-bottom: 20px;
  padding: 0px 0px 0px 20px;
}

.outline-scene-title {
  font-weight: bold;
}

.outline-scene-assets {
  padding: 0px 20px;
}

.outline-scene-assets-title {
  border-bottom: 1px solid #cccccc;
  margin-top: 10px;
  text-transform: capitalize;
}

.outline-scene-asset-title {
  font-style: italic;
}
