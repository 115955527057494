/* title page */
.script .title-page h1 {
  margin-top: 50%;
  margin-bottom: 34px;
  text-align: center;
  width: 90.5%;
  font-size: 24px;
  font-family: var(-body-font);
}
.script .title-page p.credit {
  text-align: center;
  width: 90.5%;
}
.script .title-page p.author,
.script .title-page p.authors {
  margin-bottom: 32px;
  margin-top: 0;
  text-align: center;
  width: 90.5%;
}
.script .title-page p.source {
  margin-bottom: 32px;
  text-align: center;
  width: 90.5%;
}
.script .title-page p.notes {
  bottom: 350px;
  position: absolute;
  right: 0;
  text-align: right;
}
.script.dpi72 .title-page p.notes {
  bottom: 252px;
  right: 72px;
}
.script.dpi100 .title-page p.notes {
  bottom: 350px;
  right: 100px;
}
.script.dpi150 .title-page p.notes {
  bottom: 525px;
  right: 150px;
}
.script .title-page p.date,
.script .title-page p.draft-date {
  bottom: 250px;
  position: absolute;
  right: 0;
  text-align: right;
}
.script.dpi72 .title-page p.date,
.script.dpi72 .title-page p.draft-date {
  bottom: 180px;
  right: 72px;
}
.script.dpi100 .title-page p.date,
.script.dpi100 .title-page p.draft-date {
  bottom: 250px;
  right: 100px;
}
.script.dpi150 .title-page p.date,
.script.dpi150 .title-page p.draft-date {
  bottom: 375px;
  right: 150px;
}
.script .title-page p.contact {
  bottom: 100px;
  position: absolute;
  right: 0;
  text-align: right;
}
.script.dpi72 .title-page p.contact {
  bottom: 72px;
  right: 72px;
}
.script.dpi100 .title-page p.contact {
  bottom: 100px;
  right: 100px;
}
.script.dpi150 .title-page p.contact {
  bottom: 150px;
  right: 150px;
}
.script .title-page p.copyright {
  bottom: 100px;
  position: absolute;
  text-align: left;
}
.script.dpi72 .title-page p.copyright {
  bottom: 72px;
}
.script.dpi100 .title-page p.copyright {
  bottom: 100px;
}
.script.dpi150 .title-page p.copyright {
  bottom: 150px;
}

/* script */
.script h2 {
  text-align: left;
  font-family: "Courier Prime", monospace;
  font-size: 16px;
}
.script h2.left-aligned {
  text-align: left;
  font-family: "Courier Prime", monospace;
}
.script h3 {
  position: relative;
}
.script h3:before {
  color: #bbb;
  content: attr(id);
  font-weight: bold;
  left: -45px;
  position: absolute;
}
.script h3:after {
  color: #bbb;
  content: attr(id);
  font-weight: bold;
  right: -45px;
  position: absolute;
}

.script div.dialogue {
  margin-left: auto;
  margin-right: auto;
  width: 68%;
}
.script div.dialogue h4 {
  margin-bottom: 0;
  margin-left: 23%;
}
.script div.dialogue p.parenthetical {
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 11%;
}
.script div.dialogue p {
  margin-bottom: 0;
  margin-top: 0;
}

.script div.dual-dialogue {
  margin: 2em 0 0.9em 2% auto;
  width: 95%;
}
.script div.dual-dialogue div.dialogue {
  display: inline-block;
  margin: 0;
  width: 45%;
}
.script div.dual-dialogue div.dialogue h4 {
  margin-top: 0;
}
.script div.dual-dialogue div.dialogue.right {
  float: right;
}

.script p.centered {
  text-align: center;
  width: 92.5%;
}

.script p.section {
  color: #bbb;
  margin-left: -30px;
}
.script p.synopsis {
  color: #bbb;
  margin-left: -20px;
}

.script span.italic {
  font-style: italic;
}
.script span.bold {
  font-weight: bold;
}
.script span.underline {
  text-decoration: underline;
}
